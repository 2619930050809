$primary: #b40303;
$primary-hover: #c83535;
$light-hover: #e2e6ea;

.navbar {
  background: var(--neutral-50, #fff);

  /* shadow 1 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}

.primary-btn {
  display: flex;
  color: #fff;
  padding: var(--spacing-5, 16px) var(--spacing-9, 48px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-1, 2px);
  border-radius: var(--spacing-3, 8px);
  background: var(--primary-red600, #b40303);
}

.subtle-btn {
  display: flex;
  color: #b40303;
  border-radius: var(--spacing-3, 8px);
  border: 1px solid var(--primary-red600, #b40303);
  padding: var(--spacing-5, 16px) var(--spacing-9, 48px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-1, 2px);
}

.schedule-card {
  border-radius: 12px;
  background: #fff;

  /* shadow 1 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}

.mobile-schedule-card {
  border-radius: 12px;
  background: #fff;
  border-width: 1px;
  border-color: #d4d3d3;
  width: 100%;
}

.book-advance-container {
  border-radius: var(--spacing-5, 16px);
  background: var(--neutral-0, #fff);
  /* shadow 4 */
  box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.1);
}

.book-advance-container,.book-advance-container-mobile {
  .ant-radio-checked .ant-radio-inner {
    border-color: #b40303;
    background-color: #b40303;
  }
}

.luggage-container {
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #000;
    background-color: #000;
  }

  :where(.css-dev-only-do-not-override-nnuwmp).ant-checkbox-wrapper:not(
      .ant-checkbox-wrapper-disabled
    ):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: #222;
    border-color: transparent;
  }
}

:where(.css-dev-only-do-not-override-nnuwmp).ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
  border-color: #939291;
  border-inline-end-width: 1px;
  z-index: 1;
}

.rounded-btn {
  display: flex;
  padding: var(--spacing-4, 12px);
  align-items: flex-start;
  gap: 10px;
  border-radius: var(--spacing-3, 8px);
  border: 1px solid var(--neutral-200, #939291);
  background: var(--neutral-0, #fff);
}

.rounded-btn-sm {
  display: flex;
  padding: var(--spacing-4, 8px);
  align-items: flex-start;
  gap: 10px;
  border-radius: var(--spacing-3, 8px);
  border: 1px solid var(--neutral-200, #939291);
  background: var(--neutral-0, #fff);
}

.header-text-3 {
  color: #484442;

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 125% */
}
.header-text-4 {
  color: #484442;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 125% */
}
.body-text-2 {
  color: #484442;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}

.text-heading-3 {
  color: var(--neutral-300, #484442);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 125% */
}

.text-label {
  color: var(--neutral-300, #484442);
  margin-left: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 112.5% */
}

.input-container {
  display: flex;
  height: 50px;
  padding: var(--spacing-3, 8px);
  justify-content: center;
  align-items: center;
  min-width: 100%;
  border-radius: var(--spacing-3, 8px);
  border: 1px solid var(--neutral-200, #939291);
  background: var(--neutral-0, #fff);
  input{
    outline: none;
  }

  &:focus {
    border: 1px solid var(--neutral-200, #ccc);
  }
  &:disabled {
    color: 'black';
  }
}

.text-caption {
  color: var(--neutral-300, #484442);

  /* Caption */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
}

.search-input,
.trip-code-search {
  margin-top: 10px;

  height: 50px;
  border-radius: var(--spacing-5, 10px);
  background: var(--neutral-0, #fff);

  /* shadow 1 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  .ant-btn {
    background: #b40303;
    height: 50px;
  }

  .ant-btn:hover {
    background: #b40303;
  }

  .ant-input-group-wrapper {
    display: inline-block;
    width: 100%;
    text-align: start;
    vertical-align: middle;
  }

  .ant-input {
    height: 40px;
    vertical-align: middle;
  }

  .ant-btn-primary {
    border-top-right-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
  }
}

.trip-code-search > input {
  font-size: 24px;
  ::placeholder {
    font-size: 24px;
  }
}

.tab-item {
  display: flex;
  padding: var(--spacing-3, 8px) var(--spacing-4, 12px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--spacing-3, 8px);
  background: var(--primary-red600, #b40303);
  color: #fff;
}

.success-popup {
  ul {
    margin-left: 30px;
  }
  ul > li {
    list-style: disc;
  }
}

.sidebar {
  .ant-checkbox-wrapper {
    transition: all 0.3s ease;
    margin: 0 8px 0 0;
    margin-bottom: 5px;
  }

  .ant-checkbox .ant-checkbox-input {
    border-width: 1px;
    background-color: #c83535;
    border-color: #c83535;
  }

  .ant-slider .ant-slider-handle::after {
    content: "";
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 5px;
    height: 5px;
    background-color: #b40303;
    // box-shadow: 0 0 0 2px #b40303;
    border-radius: 50%;
    cursor: pointer;
    transition: inset-inline-start 0.2s, inset-block-start 0.2s, width 0.2s,
      height 0.2s, box-shadow 0.2s;
  }

  .ant-slider .ant-slider-track {
    position: absolute;
    background-color: #000;
    border-radius: 1px;
    transition: background-color 0.2s;
  }

  .ant-slider-horizontal .ant-slider-track {
    height: 1px;
  }
}

.card-container {
  padding: var(--spacing-5, 16px) var(--spacing-6, 24px);

  gap: 10px;

  border-radius: var(--spacing-4, 12px);
  border: 0.5px solid var(--neutral-300, #484442);
}

.premium-container {
  border-radius: var(--spacing-3, 8px);
  background: var(--primary-red-100, #ffcecc);
  display: flex;
  padding: var(--spacing-2, 4px) var(--spacing-3, 8px);
  justify-content: center;
  align-items: center;

  gap: 10px;
}
.advance-booking-container {
  display: flex;
  width: 254px;
  height: 54px;
  padding: var(--spacing-2, 4px) var(--spacing-3, 8px);
  align-items: center;
  gap: var(--spacing-4, 12px);
  flex-shrink: 0;

  border-radius: var(--spacing-5, 16px);
  background: var(--neutral-0, #fff);

  /* shadow 1 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}

.person-info-container {
  .phone {
    background-color: #fff;
  }
}

.book-trip-btn {
  display: flex;
  width: 100%;
  padding: var(--spacing-3, 8px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-1, 2px);
  flex-shrink: 0;
  border-radius: var(--spacing-3, 8px);
  background: var(--primary-red600, #b40303);
  color: #fff;
  &:hover {
    background: #d60303;
  }
}

.btn-primary {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-1, 2px);
  flex-shrink: 0;
  border-radius: var(--spacing-3, 8px);
  background: var(--primary-red600, #b40303);
  color: #fff;
  &:hover {
    background: #d60303;
  }
}

.dropdown-container {
  display: flex;
  padding: 6px var(--spacing-3, 8px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-4, 12px);
  border-radius: var(--spacing-4, 12px);
  border: 1px solid var(--neutral-200, #939291);
  background: var(--neutral-0, #fff);
  cursor: pointer;
}

.input {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 8px;
  border: 1px solid #939291;
  background: #fff;

  &:focus {
    outline: none;
  }
}

.nav-bar {
  display: inline-flex;
  padding: var(--spacing-5, 16px) var(--spacing-8, 40px);
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  gap: 25px;
  border-top: 1px solid var(--primary-orange300, #b40303);
  background: var(--neutral-0, #fff);
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.12);
}

.mobileSeatSelection {
  transition: all 0.3s ease;
  margin-bottom: 20px;

  .ant-checkbox {
    display: none;
  }

  &.ant-checkbox-wrapper-checked,
  &:hover {
    background-color: none;
    .seat {
      color: $primary-hover;
      fill: $primary;
    }
  }

  &.ant-radio-wrapper-checked {
    background-color: rgba($primary, 0.2);
  }
}

.seatSelection {
  transition: all 0.3s ease;
  margin-bottom: 20px;

  .ant-checkbox {
    display: none;
  }

  &.ant-checkbox-wrapper-checked,
  &:hover {
    background-color: rgba($primary, 0.2);

    .seat {
      color: #2abb7f;
      fill: #2abb7f;
    }
  }

  &.ant-radio-wrapper-checked {
    background-color: rgba($primary, 0.2);
  }
}

.shareWithMinorContainer {
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    background-color: #ccc;
  }
  .ant-switch.ant-switch-checked,
  .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: $primary;
  }
}

.mobile-sort-radio,
.seatPurpose {
  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background-color: $primary;
    border-color: $primary;
  }
}


.guestSeatSelection {
  transition: all 0.3s ease;
  margin-bottom: 20px;

  .ant-radio {
    display: none;
  }

  &.ant-radio-wrapper-checked,
  &:hover {
    background-color: rgba($primary, 0.2);

    .seat {
      color: #2abb7f;
      fill: #2abb7f;
    }
  }

  &.ant-radio-wrapper-checked {
    background-color: rgba($primary, 0.2);
  }
}


.payment-opt-container {
  transition: all 0.3s ease;
  margin-bottom: 20px;

  .ant-radio {
    display: none;
    cursor: pointer;
  }
}
// .ant-checkbox-wrapper {
//   transition: all 0.3s ease;
//   margin: 0 8px 0 0;
//   margin-bottom: 20px;

//   &.ant-checkbox-wrapper-checked {
//     background-color: rgba($primary, 0.6);

//     .seat {
//       color: rgba($primary, 0.1);
//       fill: rgba($primary, 0.1);
//     }
//   }

//   .pickup__radio {
//     .ant-radio {
//       border: 5px solid #7c0000;
//       top: 0;
//       border-radius: 50%;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//     }

//     .ant-radio-inner {
//       display: block;
//       background-color: white;
//       border-color: #7c0000;
//       width: 10px;
//       height: 10px;
//     }

//     &.ant-radio-wrapper-checked,
//     &:hover {
//       // background-color: rgba($primary, 0.1);
//       border-color: #7c0000;

//       .ant-radio {
//         border: 5px solid #7c0000;
//         border-radius: 50%;
//         display: flex;
//         align-items: center;
//         justify-content: center;

//         .ant-radio-inner::after {
//           background-color: white;
//         }
//       }
//       h4 {
//         color: #7c0000;
//       }
//     }
//   }

//   @media (max-width: 480px) {
//     span {
//       padding: 0 2px !important;
//     }
//   }
// }

.wallet-container {
  background: url("../assets/img/wallet.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.wallet-btn {
  display: flex;
  width: 225px;
  padding: var(--spacing-4, 12px) var(--spacing-6, 24px);
  justify-content: space-between;
  align-items: center;
  color: #fff;
  border-radius: var(--spacing-3, 8px);
  background: var(--primary-red600, #b40303);
}

.wallet-btn-sm {
  display: flex;
  padding: var(--spacing-4, 12px) var(--spacing-6, 24px);
  justify-content: space-between;
  align-items: center;
  border-radius: var(--spacing-3, 8px);
  color: #fff;
  background: var(--primary-red600, #b40303);
}

.pagination {
  .ant-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .ant-pagination .ant-pagination-disabled,
  .ant-pagination-item {
    background: #d4d3d3;
  }

  .ant-pagination .ant-pagination-item-active {
    background: #b40303;

    border: none;
  }

  .ant-pagination .ant-pagination-item-active a {
    color: #fff !important;
  }

  .ant-pagination .ant-pagination-prev .ant-pagination-item-link {
    background: #d4d3d3;
  }

  .ant-pagination .ant-pagination-next .ant-pagination-item-link {
    background: #d4d3d3;
  }
}

@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .dh-slider-gallery {
    .swiper-slide {
      margin-right: 1rem !important;
    }

    .swiper-pagination-bullet {
      width: 40px;
      height: 4px;
    }
  }
}

.joinBusBtn {
  width: fit-content;
  &:hover {
    color: #fff;
    background-color: #e7ba60;
  }
}

.hero-container {
  position: relative;
  display: inline-block;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: linear-gradient(
    //   to right,
    //   rgba(0, 0, 0, 0.95),
    //   rgba(0, 0, 0, 0.1)
    // );
    background: linear-gradient(
      91deg,
      rgba(0, 0, 0, 0.86) 33.86%,
      rgba(255, 255, 255, 0.1) 206.49%
    );
    display: flex;

    align-items: center;
  }
}

.btn-subtle {
  color: #b40303;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
}

.hero-two {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: linear-gradient(
    192deg,
    #ffdfde -3.2%,
    rgba(255, 255, 255, 0.49) 119.21%
  );

  .svg-item {
    transform: rotate(-14.481deg);
    fill: linear-gradient(145deg, #ffcac6 6.43%, rgba(255, 255, 255, 0) 96%);
  }

  .android-btn,
  .ios-btn {
    border-radius: var(--spacing-3, 8px);
    border: 2px solid var(--primary-r-500, #ff0800);
    display: flex;
    padding: var(--spacing-3, 8px);
    flex-direction: row;
    align-items: flex-start;
    gap: 2px;

    @media (max-width: 400px) {
      padding: 4px;
    }
  }

  .phone {
    width: 98%;
    height: auto;
    transform: rotate(2deg);
    flex-shrink: 0;
  }
}

.hero-two-large {
  .android-btn,
  .ios-btn {
    border-radius: 8px;
    background-color: #000;
    color: #fff;
    display: flex;

    padding: 5px 7px 3px 5px;
    flex-direction: row;
    align-items: flex-start;
    gap: 2px;
  }
}

.hero-two-phone-container,
.footer-phone-container,
.footer-download-btn {
  @media (max-width: 300px) {
    display: block;
  }
}

.custom-card-container {
  position: relative;
  display: inline-block;
  border-radius: 10px;

  .custom-card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20.89%, #000 65.96%);
  }
}

.do-more {
  @media (max-width: 300px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

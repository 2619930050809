// @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;700;900&display=swap');

* {
  scroll-behavior: smooth;
  font-family: 'IBM Plex Sans', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #65656566;
  border-radius: 10px;
}

.phone > input {
  outline: none;
}

body {
  color: #484442;
}

.ant-picker-ok {
  button[disabled] {
    background-color: #f5f5f5 !important;
  }

  button:not([disabled]) {
    background-color: #b40303;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
